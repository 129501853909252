@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-link.active {
  color: #31d9c2 !important;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .boxes {
    grid-template-columns: 1fr;
  }

  .form {
    width: 90%;
  }

  .ticket-created h2,
  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: rgb(153, 105, 0);
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
footer {
  padding: 2em 0;
}

.footer-07 {
  background: #000000;
}
.footer-07 a {
  color: #a3de83;
}
.footer-07 p {
  color: rgba(255, 255, 255, 0.3);
}
.footer-07 .footer-heading {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}
.footer-07 .footer-heading .logo {
  color: #fff;
}
.footer-07 .menu {
  margin-bottom: 30px;
}
.footer-07 .menu a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.footer-07 .ftco-footer-social li a {
  background: transparent;
  border: 1px solid #a3de83;
}
.menu a {
  text-decoration: none;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
font-size: 60px;
background-color: #d5d4d4;
padding: 10%;
}
